<template>
  <div class="footer">
    <div class="footer-box">
      <p>联系我们</p>
      <div class="footer-buttom">
        <div class="left">
          <ul>
            <li>联系电话：027-87001446</li>
            <li style="padding: 10px 0;">电子邮箱：yunfeng@whmw.net</li>
            <li>
              联系地址：湖北省武汉市东湖高新 技术开发区光谷大道金融港B5栋1001
            </li>
          </ul>
        </div>
        <div class="m-left">
          <ul>
            <li>
              <a
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006228"
                target="https://www.whmw.net/"
                >鄂公网安备42018502006228号</a
              >
            </li>
            <li style="padding: 10px 0;">
              <a href="https://beian.miit.gov.cn/" target="__blank"
                >鄂ICP备2020019193号-5</a
              >
            </li>
            <li>武汉觅网科技有限公司</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld'
}
</script>

<style scoped lang="scss">
@media screen and(min-width: 901px) and(max-width: 3000px) {
  .footer {
    width: 100%;
    height: 320px;
    background: #333333;
    opacity: 1;
    display: flex;
    justify-content: center;
  }
  .footer-box {
    // width: 74%;
    width: 87%;
    p {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #ffffff;
      opacity: 1;
      margin-top: 58px;
    }
  }
  .footer-buttom {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
  }
  .left li {
    // padding-right: 30px;
    box-sizing: border-box;
  }
  .m-left ul {
    margin-left: 30px;
    box-sizing: border-box;
  }
  ul {
    list-style: none;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
  }
  a {
    list-style: none;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
    text-decoration: none;
  }
}
@media screen and (max-width: 900px) {
  .footer {
    width: 100%;
    height: 270pt;
    background: #333333;
    opacity: 1;
    display: flex;
    justify-content: center;
  }
  .footer-box {
    width: 90%;
    p {
      font-size: 15pt;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #ffffff;
      opacity: 1;
      margin-top: 21pt;
    }
  }
  .footer-buttom {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    margin-top: 16pt;
  }
  .m-left {
    margin-top: 38pt;
  }
  ul {
    list-style: none;
    // font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
  }
  a {
    list-style: none;
    // font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
    text-decoration: none;
  }
}
</style>
