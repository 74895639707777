import { axios } from '../plugins/axios'

//猪崽详情
export function getPigDetail(data) {
  return axios.request({
    url: '/mch/livestock/pig',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//确认喂养
export function getFeed(data) {
  return axios.request({
    url: '/mch/livestock/feed',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//协议
export function getXieyi(data) {
  return axios.request({
    url: '/api/Publics/get_xieyi',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//确认档案
export function File(data) {
  return axios.request({
    url: 'public/pig_info',
    method: 'Get',
    params: {
      ...data
    }
  })
}

//喂养记录
export function record(data) {
  return axios.request({
    url: '/api/public/feed_list',
    method: 'Get',
    params: {
      ...data
    }
  })
}

// 验证码
export function sendSms(data) {
  return axios.request({
    url: '/api/Publics/sendSmsH5',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//  验证好友
export function bindFriend(data) {
  return axios.request({
    url: '/api/Publics/bindFriend',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 获取直播地址
export function getLiveUrl(data) {
  return axios.request({
    url: '/api/Publics/getLiveUrl',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 获取下载地址
export function getDownUrl(data) {
  return axios.request({
    url: '/api/Publics/getDownUrl',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 分享
export function shareUse(data) {
  return axios.request({
    url: '/api/public/share_use',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 获取农户下载地址
export function getMchDownUrl(data) {
  return axios.request({
    url: '/mch/Publics/getDownUrl',
    method: 'POST',
    params: {
      ...data
    }
  })
}
// 打印页面，
export function dump_pig(data) {
  return axios.request({
    url: '/api/public/dump_pig',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//获取萤石云token
export function getToken() {
  return axios.request({
    url: '/api/public/get_token',
    method: 'get'
  })
}

// 游乐场 猪崽档案
export function pigInfos(data) {
  return axios.request({
    url: '/api/public/pig_info',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 游乐场 喂养记录
export function feedList(data) {
  return axios.request({
    url: '/api/public/feed_list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 开关
export function ranchData(data) {
  return axios.request({
    url: '/api/public/ranch',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 动态列表
export function trendst(data) {
  return axios.request({
    url: '/api/trend/list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 动态列表
export function trendfo(data) {
  return axios.request({
    url: '/api/trend/info',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//猪仔价格
export function pig_list(data) {
  return axios.request({
    url: '/api/public/pig_list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//喂养价格
export function meal_list(data) {
  return axios.request({
    url: '/api/public/meal_list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//屠宰价格
export function butcher_config(data) {
  return axios.request({
    url: '/public/butcher_config',
    method: 'GET',
    params: {
      ...data
    }
  })
}
